@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-outfit;
  }

  :root {
    --navbar-tabs-bg-color: #1d262c;
    --brand-base: #f47321;
  }

  .footer {
    @apply w-full grid grid-cols-2 h-[317px] mt-auto bg-[var(--navbar-tabs-bg-color)] text-[var(--navbar-tabs-text-color)];
  }
}
